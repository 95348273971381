<template>
   <AppPage :name="'home'">
     <div class="main">
      <div class="content">

        <div class="baoliao-item flex flex-main-between pl-25 pr-25 pt-20 pb-20">
          <div class="mr-20"><img :src="card.img" alt="" class="block" style="width: 18rem; height: 18rem;border-radius: 3px;"></div>
          <div class="flex flex-column flex-main-between" style="width: calc(100% - 20rem)">
            <div class="text-4a fs-18 fw-700 lh-25">{{card.title}}</div>
            <div class="">
              <div style="background-color: #FFF1F1;padding: .5rem .8rem; border-radius: .5rem;" class="text-primary fs-15 lh-15 inline-block mb-20">{{card.desc}}</div>
              <div class="fs-22 lh-22 fw-700 text-primary mb-5" v-if="card.price != ''">{{card.price}}元</div>
              <div class="flex flex-main-between flex-cross-end">
                <div>
                  
                </div>
                <a :href="card.clickUrl" target="_blank">
                  <div style="background-color: #FF0520; width: 17rem; height: 3.4rem;line-height: 3.4rem; border-radius: 2rem;" class="flex flex-cross-center flex-main-center">
                    <div class="mr-5"><img src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/160/images/1646277672623698003.png" class="block" style="width: 2rem; height: 2rem;" alt=""></div>
                    <div class="text-center text-white fs-16 ">手机扫码购买</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="fs-16 text-666 fw-700 mt-30 mb-30 pl-20">商品详情</div>

        <div class="pl-20 pr-20">
          <img :src="item" alt="" class="block full-width" v-for="(item, i) in detailImages" :key="i" />
        </div>

        <div class="fs-14 text-9b mt-20 pl-20">{{card.dateStr}}更新</div>

      </div>

    </div>
    <div class="ml-20"></div>
    <div class="side">
      <AppQRCode />
      <Copyright />
    </div>
   </AppPage>
</template>
<script>
export default {
  name: "Detail",
  props: {
    
  },
  data() {
     return {
       card: this.$route.query,
       detailImages: []
     }
  },
  created(){
    this.detailImages = this.card.detailImages.split(',')
    console.log(this.card)
  },
  computed:{
  },
  methods:{
  },
}
</script>
<style lang="css" scoped>
.main {
  width: 834px;
  background: white;
}
.side {
  width: 314px;
}
</style>
